import React, { useCallback, useEffect, useState, useRef } from 'react'
import {
  Tab,
  Tabs,
  useMediaQuery,
  useTheme,
  InputAdornment,
  IconButton,
  Typography,
  Button,
  Divider,
  Box,
} from '@mui/material'
import { withStyles } from 'tss-react/mui'
import { withTranslation } from 'react-i18next'
import classNames from 'clsx'
import SearchResultsTop, { SearchResultItem } from './SearchResultsTop'
import { debounce } from 'underscore'
import apiClient from 'shared-components/utils/ApiClient'
import { useIsLoggedIn } from '../../utils/hooks'
import TextInput from '../../shared-components/common/TextInput'
import {
  SearchOutlined,
  ArrowBack,
  Close,
  History,
  DeleteOutline,
} from '@mui/icons-material'
import { useRouter } from 'next/router'
import FullScreenSheet from '../modals/FullScreenSheet'
import BashButton, {
  BashButtonType,
} from '../../shared-components/buttons/BashButton'

// Local storage key for recent searches
const RECENT_SEARCHES_KEY = 'recentSearches'
const MAX_RECENT_SEARCHES = 10

// Save a search result to local storage
const saveRecentSearch = (item) => {
  try {
    // Get existing searches
    const existingSearchesJSON = localStorage.getItem(RECENT_SEARCHES_KEY)
    let existingSearches = existingSearchesJSON
      ? JSON.parse(existingSearchesJSON)
      : []

    // Check if this item already exists
    const existingIndex = existingSearches.findIndex(
      (search) => search.id === item.id && search.type === item.type,
    )

    // If it exists, remove it so we can add it to the top
    if (existingIndex > -1) {
      existingSearches.splice(existingIndex, 1)
    }

    // Add the new item to the beginning
    existingSearches.unshift(item)

    // Keep only the latest MAX_RECENT_SEARCHES
    existingSearches = existingSearches.slice(0, MAX_RECENT_SEARCHES)

    // Save back to localStorage
    localStorage.setItem(RECENT_SEARCHES_KEY, JSON.stringify(existingSearches))
  } catch (error) {
    console.error('Error saving recent search', error)
  }
}

// Clear all recent searches
const clearRecentSearches = () => {
  localStorage.removeItem(RECENT_SEARCHES_KEY)
}

// Remove a specific recent search
const removeRecentSearch = (itemId, itemType) => {
  try {
    const existingSearchesJSON = localStorage.getItem(RECENT_SEARCHES_KEY)
    if (!existingSearchesJSON) return

    let existingSearches = JSON.parse(existingSearchesJSON)
    existingSearches = existingSearches.filter(
      (search) => !(search.id === itemId && search.type === itemType),
    )

    localStorage.setItem(RECENT_SEARCHES_KEY, JSON.stringify(existingSearches))
  } catch (error) {
    console.error('Error removing recent search', error)
  }
}

const styles = (theme) => ({
  container: {
    width: '100%',
    maxWidth: '600px',
    height: '600px',
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.spacing(2),
    padding: theme.spacing(2, 0),
    display: 'flex',
    flexDirection: 'column',
  },
  containerFullHeigth: {
    height: '90vh',
    [theme.breakpoints.down('sm')]: {
      height: '100vh',
    },
  },
  searchBarContainer: {
    padding: theme.spacing(2),
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  searchBar: {
    background: theme.palette.grey[200],
    height: '40px',
    fontSize: '16px',
    padding: theme.spacing(0, 2),
    paddingRight: theme.spacing(1.5),
    borderRadius: theme.spacing(8),
    width: '100%',
  },
  searchIcon: {
    color: theme.palette.text.secondary,
    height: '0.8em',
  },
  backButton: {
    padding: theme.spacing(0.5),
    color: theme.palette.grey[700],
  },
  tabs: {
    '& .MuiTabs-indicator': {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
    },
    '& .MuiTabs-flexContainer': {
      justifyContent: 'flex-start',
      paddingLeft: theme.spacing(2),
    },
  },
  myIndicator: {
    '&:after': {
      content: '""',
      width: '100%',
      height: 1,
      backgroundColor: theme.palette.text.primary,
    },
  },
  selected: {
    color: `${theme.palette.text.primary} !important`,
    fontWeight: '500 !important',
  },
  tabItem: {
    minWidth: 'auto',
    padding: theme.spacing(2, 2),
    textTransform: 'none',
    fontSize: '14px',
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  hidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: '1px',
    margin: '-1px',
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    width: '1px',
  },
  tabContent: {
    flex: 1,
    overflow: 'auto',
  },
  recentSearchesContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'auto',
    padding: theme.spacing(0, 2),
  },
  recentSearchesHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2, 1),
    paddingTop: theme.spacing(3),
  },
  recentSearchItem: {
    position: 'relative',
    borderRadius: theme.spacing(1),
    boxSizing: 'border-box',
  },
  recentSearchItemIcon: {
    color: theme.palette.grey[700],
    marginRight: theme.spacing(2),
  },
  recentSearchItemText: {
    flex: 1,
  },
  clearAllButton: {
    fontSize: '14px',
    marginRight: theme.spacing(-2),
  },
  emptyState: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    color: theme.palette.text.secondary,
  },
  removeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 1,
    padding: theme.spacing(0.5),
  },
  modalRoot: {
    '& .MuiDialog-paper': {
      height: '600px !important',
    },
  },
})

const wrapFetchWithModel = (fetchFunc, type) => async (query, page, size) => {
  const results = await fetchFunc(query, page, size)
  return results.map((model) => ({
    type,
    model,
  }))
}

const getSearchFetch = (tab) => {
  switch (tab) {
    case 0:
      return apiClient.search.all
    case 1:
      return wrapFetchWithModel(apiClient.event.findByName, 'EVENT')
    case 2:
      return wrapFetchWithModel(
        apiClient.organisation.findByName,
        'ORGANISATION',
      )
    case 3:
      return wrapFetchWithModel(apiClient.user.findUsers, 'USER')
  }
}

const SearchResults = ({
  classes,
  t,
  query,
  setQuery,
  closeModal,
  focusedSearch,
  excludeFriends = false,
}) => {
  const [value, setValue] = React.useState(0)
  const theme = useTheme()
  const isLoggedIn = useIsLoggedIn()
  const fullScreen = useMediaQuery(theme.breakpoints.up('sm'))
  const [debouncedQuery, setDebouncedQuery] = useState('')
  const searchRef = useRef(null)
  const [recentSearches, setRecentSearches] = useState([])
  const router = useRouter()

  const updateQueryThrottled = useCallback(
    debounce((searchQuery) => {
      setDebouncedQuery(searchQuery)
    }, 200),
    [setDebouncedQuery],
  )

  useEffect(() => {
    updateQueryThrottled(query)
  }, [query])

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleQuery = (event) => {
    setQuery(event.target.value)
  }

  useEffect(() => {
    searchRef.current?.focus()

    // Load recent searches from localStorage
    try {
      const savedSearches = localStorage.getItem(RECENT_SEARCHES_KEY)
      if (savedSearches) {
        setRecentSearches(JSON.parse(savedSearches))
      }
    } catch (error) {
      console.error('Error loading recent searches', error)
    }
  }, [])

  const handleClearAllRecentSearches = () => {
    clearRecentSearches()
    setRecentSearches([])
  }

  const handleRemoveRecentSearch = (itemId, itemType) => {
    removeRecentSearch(itemId, itemType)
    setRecentSearches((prev) =>
      prev.filter(
        (search) => !(search.id === itemId && search.type === itemType),
      ),
    )
  }

  const handleRecentSearchClick = (item) => {
    // We only handle actual result items, not queries
    const itemCopy = { ...item }
    saveRecentSearch(itemCopy)
  }

  // Mock functions needed by SearchResultItem
  const goToEvent = (event) => {
    // Check if this is a recent search item being clicked
    const isRecentItem = recentSearches.some(
      (item) => item.type === 'EVENT' && item.id === event.id,
    )

    // If it's a recent item, use the item directly instead of creating a new one
    if (isRecentItem) {
      const existingItem = recentSearches.find(
        (item) => item.type === 'EVENT' && item.id === event.id,
      )
      saveRecentSearch(existingItem) // This will just reorder it to the top
    }

    closeModal()
    if (event.code) {
      router.push(`/e/${event.code}`)
    } else {
      router.push(`/events/${event.id}`)
    }
  }

  const goToProfile = (page) => {
    // Check if this is a recent search item being clicked
    const isRecentItem = recentSearches.some(
      (item) => item.type === 'ORGANISATION' && item.id === page.id,
    )

    // If it's a recent item, use the item directly instead of creating a new one
    if (isRecentItem) {
      const existingItem = recentSearches.find(
        (item) => item.type === 'ORGANISATION' && item.id === page.id,
      )
      saveRecentSearch(existingItem) // This will just reorder it to the top
    }

    closeModal()
    if (page.username) {
      router.push(`/${page.username}`)
    } else {
      router.push(`/pages/${page.id}`)
    }
  }

  const goToUser = (user) => {
    // Check if this is a recent search item being clicked
    const isRecentItem = recentSearches.some(
      (item) => item.type === 'USER' && item.id === user.id,
    )

    // If it's a recent item, use the item directly instead of creating a new one
    if (isRecentItem) {
      const existingItem = recentSearches.find(
        (item) => item.type === 'USER' && item.id === user.id,
      )
      saveRecentSearch(existingItem) // This will just reorder it to the top
    }

    closeModal()
    router.push(`/users/${user.id}`)
  }

  return (
    <FullScreenSheet
      open={focusedSearch}
      onClose={closeModal}
      hideTitle
      smallOnMobile={false}
      paperSx={{
        maxWidth: fullScreen ? '600px' : '100%',
        width: '100%',
        height: fullScreen ? '600px !important' : '100%',
        maxHeight: fullScreen ? '600px !important' : '100vh',
        backgroundColor: theme.palette.background.default,
        borderRadius: fullScreen ? theme.spacing(2) : 0,
        padding: 0,
        margin: fullScreen ? 'auto' : 0,
        marginTop: fullScreen ? theme.spacing(12.5) : 0, // 100px from top on desktop
        boxShadow: fullScreen
          ? '0px 4px 5px rgba(0,0,0,0.04), 0px 4px 5px rgba(0,0,0,0.04)'
          : 'none',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
      className={classes.modalRoot}
      sx={{
        '& .MuiDialog-paper': {
          height: fullScreen ? '600px !important' : '100%',
        },
      }}
    >
      <div className={classes.searchBarContainer}>
        {!fullScreen && (
          <IconButton
            className={classes.backButton}
            onClick={closeModal}
            size='small'
          >
            <Close />
          </IconButton>
        )}
        <label className={classes.hidden} htmlFor='search-field'>
          Search
        </label>
        <TextInput
          className={classes.searchBar}
          id='search-field'
          placeholder={t('startTyping')}
          type='search'
          size='small'
          aria-labelledby='search-field'
          aria-label='Search'
          variant='outlined'
          onChange={handleQuery}
          value={query}
          autoComplete='off'
          autoFocus
          onFocus={(e) => {
            e.target.select()
          }}
          startAdornment={
            <InputAdornment position='start'>
              <SearchOutlined className={classes.searchIcon} />
            </InputAdornment>
          }
          inputRef={searchRef}
        />
      </div>

      {query === '' ? (
        // Show recent searches when search is empty
        <div className={classes.recentSearchesContainer}>
          {recentSearches.length > 0 ? (
            <>
              <div className={classes.recentSearchesHeader}>
                <Typography variant='subtitle2'>
                  {t('recentSearches')}
                </Typography>
                <BashButton
                  type={BashButtonType.CLEAR_PRIMARY}
                  className={classes.clearAllButton}
                  onClick={handleClearAllRecentSearches}
                  height={32}
                >
                  {t('clearAll')}
                </BashButton>
              </div>

              {recentSearches.map((item) => (
                <div
                  key={`${item.type}-${item.id || Math.random().toString(36).substring(2, 10)}`}
                  className={classes.recentSearchItem}
                >
                  <Box position='relative'>
                    <SearchResultItem
                      model={item}
                      goToEvent={goToEvent}
                      goToPage={goToProfile}
                      goToProfile={goToUser}
                      hideAddFriendButton={true}
                    />
                  </Box>

                  <IconButton
                    className={classes.removeButton}
                    size='small'
                    onClick={(e) => {
                      e.stopPropagation()
                      handleRemoveRecentSearch(item.id, item.type)
                    }}
                  >
                    <Close fontSize='small' />
                  </IconButton>
                </div>
              ))}
            </>
          ) : (
            <div className={classes.emptyState}>
              <History
                style={{ fontSize: 48, marginBottom: theme.spacing(2) }}
              />
              <Typography variant='body1'>{t('noRecentSearches')}</Typography>
            </div>
          )}
        </div>
      ) : (
        // Show search results when query is not empty
        <>
          <Tabs
            value={value}
            onChange={handleChange}
            variant='standard'
            aria-label='search tabs'
            className={classes.tabs}
            classes={{
              indicator: classes.myIndicator,
            }}
            TabIndicatorProps={{
              children: <span />,
            }}
          >
            <Tab
              classes={{
                selected: classes.selected,
              }}
              className={classes.tabItem}
              label={t('searchResults.top')}
            />
            <Tab
              classes={{
                selected: classes.selected,
              }}
              className={classes.tabItem}
              label={t('searchResults.events')}
            />
            <Tab
              classes={{
                root: classes.tabItem,
                selected: classes.selected,
              }}
              className={classes.tabItem}
              label={t('searchResults.profiles')}
            />
            {isLoggedIn && (
              <Tab
                classes={{
                  selected: classes.selected,
                }}
                className={classes.tabItem}
                label={t('searchResults.users')}
              />
            )}
          </Tabs>
          <div className={classes.tabContent}>
            <SearchResultsTop
              key={debouncedQuery + value}
              closeModal={closeModal}
              query={debouncedQuery}
              fetchSearch={getSearchFetch(value)}
              onResultClick={(result) => {
                console.log(
                  'Search result clicked, type:',
                  result.type,
                  'model:',
                  result.model,
                )

                let searchItem = result // Just save the result directly, which already has the correct structure

                saveRecentSearch(searchItem)
              }}
            />
          </div>
        </>
      )}
    </FullScreenSheet>
  )
}

SearchResults.propTypes = {}

export default withStyles(withTranslation('common')(SearchResults), styles)
